






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    Modal: () => import('@/components/Shared/Modal.vue')
  },
})
export default class SitesModal extends Vue {
  @Prop({ default: false }) public show!: boolean;

  private validate() {
    this.$emit('sign');
    this.$emit('close');
  }
}
